body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #ffffff !important;
}

/* overrides */
.accounts-radio-group .MuiRadio-colorSecondary.Mui-checked {
  color: #61c16c;
}

.accounts-radio-group legend {
  color: #707070 !important;
}

.accounts-radio-group .MuiGrid-root.MuiGrid-item,
.staff-new .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 16px;
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  color: #707070 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #707070 !important;
}

.staff-new .MuiFormLabel-root.Mui-error {
  color: #707070 !important;
  font-size: 16px;
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
}

.MuiInput-underline.Mui-error:after {
  border-bottom-color: #ac0b1f !important;
}
